import React from "react";
import "./index.scss";

import image_1 from "./assets/D_food1.png";
import image_2 from "./assets/D_food2.png";
import image_3 from "./assets/D_food3.png";

const Intro = () => {
  return (
    <div id="intro">
      <div
        id="divider_id"
        className="website-divider-container-27295">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="divider-img-27295 vflip"
          viewBox="0 0 1080 200"
          preserveAspectRatio="none">
          <path d="M 0,100 C 51.208549,93.32062 78.809127,7.2350839 182.56961,4.0716406 293.00946,-1.3182614 349.63298,138.16937 446.48874,139.88562 540,139.37541 544.656,48.99481 627.86135,46.374354 702.71039,44.01707 732.31768,168.6649 828.48844,165.91877 909.3509,163.60977 972.01675,54.783519 1080,163.88 V 200 H 0 Z"></path>
        </svg>
      </div>
      <div className="text">
        <q>
          Food is the language of the Mediterranean, each dish a
          story whispered from ancient kitchens, carrying the
          essence of sun-drenched lands and sea-kissed shores
        </q>
      </div>
      <div id="images">
        <div id="image_2">
          <img src={image_2} />
        </div>
        <div id="image_3">
          <img src={image_3} />
        </div>
        <div id="image_1" className="desktop-only">
          <img src={image_1} />
        </div>
      </div>
    </div>
  );
};

export default Intro;
