// Get global styling
import "./App.scss";

// Get sections
import VideoSection from "./sections/video_section";
import Legacy from "./sections/legacy_of_excellence";
import SquareBites from "./sections/hand_crafted";
import LowCalorie from "./sections/low_calorie";
import Quote from "./sections/food_is_a_language";
import Behind_the_scenes from "./sections/behind_the_scenes"
import Contact from "./sections/contact_us";
import SiteFooter from "./sections/SiteFooter";

//
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

function App() {
  return (<>
    {/* Get fonts */}
    <link rel="stylesheet" href="https://use.typekit.net/bkj4toq.css" />

    <div className="App">
      <VideoSection />
      <Legacy />
      <SquareBites />
      <LowCalorie />
      <Quote />
      <Contact />
      <SiteFooter />
    </div>
  </>
  );
}

export default App;
