import React from "react";
import "./index.scss";

import vid from "./resources/food_shafik.mp4";

let LowCalorie = () => {
  return (
    <section id="low-calorie">
      <div id="plain-text">
        we proudly present a selection of
      </div>
      <h2 id="low-calorie">Low Calorie</h2>
      <h2 id="gluten-free">Gluten Free</h2>
      <div>
        <video src={vid} id="video" autoPlay muted loop />
      </div>
    </section>
  );
};

export default LowCalorie;
